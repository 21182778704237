import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppTxtJson } from '@wsbc/ux-lib';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {
  EnvironmentJson,
  FeatureFlagsJson,
  IEnvironmentJson,
} from './environments/environment.model';

if (environment.production) {
  enableProdMode();
}

// global variable for eager loading
declare const __environmentJsonResponse: Promise<{
  environmentJson: IEnvironmentJson;
  appTxtJson: any;
}>;

__environmentJsonResponse
  .then(({ environmentJson, appTxtJson }) => {
    platformBrowserDynamic([
      {
        provide: EnvironmentJson,
        useValue: environment.fixConfiguration
          ? environment.fixConfiguration(environmentJson)
          : environmentJson,
      },
      {
        provide: AppTxtJson,
        useValue: appTxtJson,
      },
      {
        provide: FeatureFlagsJson,
        useValue: environmentJson.featureFlags ?? {},
      },
    ])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  })
  .catch((err) => console.error(err));
