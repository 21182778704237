import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appStartGuard } from 'src/app/app-common/guards/app-start.guard';

import { BeforeYouBeginComponent } from './pages/before-you-begin/before-you-begin.component';
import { EligibilityQuizChannelsComponent } from './pages/eligibility-quiz-channels/eligibility-quiz-channels.component';
import { EligibilityQuizInjuryComponent } from './pages/eligibility-quiz-injury/eligibility-quiz-injury.component';
import { EligibilityQuizInstructionsComponent } from './pages/eligibility-quiz-instructions/eligibility-quiz-instructions.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'eligibilityQuizInjury',
    pathMatch: 'full',
    canMatch: [appStartGuard],
  },
  {
    path: 'eligibilityQuizInjury',
    component: EligibilityQuizInjuryComponent,
    canLoad: [appStartGuard],
  },
  {
    path: 'eligibilityQuizInstructions',
    component: EligibilityQuizInstructionsComponent,
    canLoad: [appStartGuard],
  },
  {
    path: 'eligibilityQuizChannels',
    component: EligibilityQuizChannelsComponent,
    canLoad: [appStartGuard],
  },
  {
    path: 'eligibilityQuizBeforeYouBegin',
    component: BeforeYouBeginComponent,
    canLoad: [appStartGuard],
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error-page.module').then((m) => m.ErrorPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
