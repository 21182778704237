<!-- Application Title -->
<section>
  <div class="container">
    <div class="row pt-4">
      <div class="col-md-12">
        <h1>Report your injury</h1>
      </div>
    </div>
  </div>
</section>

<!-- Description-->
<section>
  <div class="container">
    <div class="row">
      <div class="col">
        <h2>We're currently updating our tools for reporting injuries online</h2>
        <p>
          During this transition period, some sections of the online form will look differently than
          others. If you prefer to report your claim over the phone, our Teleclaim team would be
          happy to assist you.
        </p>
      </div>
    </div>
    <div class="row">
      <!-- Call our Teleclaim Team -->
      <div class="col-lg-4">
        <div class="card mb-2">
          <div class="card-body">
            <h3 class="pt-0">Call our Teleclaim team</h3>
            <div class="pb-3">Monday to Friday 8 a.m. to 6 p.m.</div>
            <span
              ><i class="fa fa-phone-square txt-grey mr-2" aria-hidden="true"></i
              ><a href="tel:6042318888">604.231.8888</a></span
            ><br />
            <span
              ><i class="fa fa-phone-square txt-grey mr-2" aria-hidden="true"></i
              ><a href="tel:18889675377">1.888.967.5377</a> (toll-free)</span
            ><br />
          </div>
        </div>
      </div>

      <!-- Report Online -->
      <div class="col-lg-4">
        <div class="card mb-2">
          <div class="card-body">
            <h3 class="pt-0">Report online</h3>
            <p>You can report your injury using the older version of our online form.</p>
            <a class="btn-blue btn-sm btn mr-2 mb-2" (click)="onCreateReportLinkClick($event)"
              >Continue online</a
            >
          </div>
        </div>
      </div>

      <!-- Fax or mail -->
      <div class="col-lg-4">
        <div class="card mb-2">
          <div class="card-body">
            <h3 class="pt-0">Fax or mail</h3>
            <p>
              You can report your injury by completing our fillable PDF form and mailing or faxing
              it to us.
            </p>
            <a class="" [href]="urls.form6PdfUrl">Fill out the form</a>
          </div>
        </div>
      </div>

      <!-- Requirement Cards -->
      <div class="col-12 fadein">
        <h2>What you'll need</h2>

        <div class="grid-3 my-3 mb-4">
          <!-- PHN -->
          <div class="py-3 d-flex justify-content-start align-items-start lightcard">
            <img alt="" class="landericon" src="assets/img/lander-phn.svg" />
            <div class="pl-2">
              <b>Personal Health Number (PHN)</b>
              <div>From your BC’s Services Card and/or driver’s licence</div>
            </div>
          </div>
          <!-- Medical -->
          <div class="py-3 d-flex justify-content-start align-items-start lightcard">
            <img alt="" class="landericon" src="assets/img/lander-medical.svg" />
            <div class="pl-2">
              <b>First medical visit</b>
              <div>Details of who you saw and what treatment you received</div>
            </div>
          </div>
          <!-- Contract -->
          <div class="py-3 d-flex justify-content-start align-items-start lightcard">
            <img alt="" class="landericon" src="assets/img/lander-contract.svg" />
            <div class="pl-2">
              <b>Employer contact</b>
              <div>Employer’s name, address, and phone number</div>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <b>If you've experienced a loss in income due to your injury, you'll also need:</b>
        </div>
        <!-- Latest Paystub -->
        <div class="grid-3 my-3">
          <div class="py-3 d-flex justify-content-start align-items-start lightcard">
            <img alt="" class="landericon" src="assets/img/lander-paystub.svg" />
            <div class="pl-2">
              <b>Latest pay stub</b>
              <div>A summary of your gross pay, taxes, and deductions</div>
            </div>
          </div>
          <!-- Earnings -->
          <div class="py-3 d-flex justify-content-start align-items-start lightcard">
            <img alt="" class="landericon" src="assets/img/lander-earnings.svg" />
            <div class="pl-2">
              <b>Earnings </b>
              <div>Earnings from the last 12 months</div>
            </div>
          </div>
          <!-- SIN -->
          <div class="py-3 d-flex justify-content-start align-items-start lightcard">
            <img alt="" class="landericon" src="assets/img/lander-sin.svg" />
            <div class="pl-2">
              <b>Social Insurance Number (SIN)</b>
              <div>Used for creating a T5 slip that is sent to you at the end of year</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Next Actions -->
<section class="mt-3">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <a class="btn-outline-blue btn-md btn mr-2 mb-2" (click)="goBack()">Back</a>
      </div>
    </div>
  </div>
</section>

<app-continue-online-modal #continueOnlineModal></app-continue-online-modal>
