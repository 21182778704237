import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AppSessionEffects } from './app-session.effects';
import * as fromAppSession from './app-session.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAppSession.appSessionFeatureKey, fromAppSession.reducer),
    EffectsModule.forFeature([AppSessionEffects]),
  ],
})
export class AppSessionModule {}
