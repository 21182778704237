import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AssessmentComponent } from '../assessment/assessment.component';

@Component({
  selector: 'app-before-you-begin',
  standalone: true,
  imports: [RouterModule, AssessmentComponent, CommonModule],
  templateUrl: './before-you-begin.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BeforeYouBeginComponent {
  showAssessment = false;
  showButton: boolean = false;

  handleInjuryType(type: string) {
    this.showButton = true;
    console.log('type', type);

    if (type === 'single') {
      this.showAssessment = true;
    } else {
      this.showAssessment = false;
    }
  }
}
