import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

/**
 * This is application wide common module.
 * If the components/directive is specific to your feature,
 * please create feature common module and organize it that way instead.
 */

@NgModule({
  declarations: [],
  exports: [],
  imports: [CommonModule],
})
export class AppCommonModule {}
