<section>
  <div class="container">
    <div class="row pt-4">
      <div class="col-md-12">
        <h1>Report an injury</h1>
      </div>
    </div>
  </div>
</section>

<!-- INTROTEXT -->
<section>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 style="">Before you begin</h2>
        <p>
          We’re here to help people who are injured at work get the medical care and services they
          need to support their recovery. This tool will guide you through reporting a workplace
          injury or disease to our Claims and Rehabilitation Services team.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="fadein">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <!-- OPTION 1 -->
        <div id="option-physical" class="option1 content-item">
          <div class="pb-3 pt-3">
            <div class="pb-0 mb-0">
              <b
                >Did your injury or condition occur from a single incident or shift, or did it
                develop over time?</b
              >
            </div>
            <div class="pb-3">
              Injuries that develop over time can take years, months, weeks, or days to manifest.
            </div>

            <label class="container-radio">
              Single incident or shift
              <input
                type="radio"
                id="option-single"
                name="injurylength"
                (change)="handleInjuryType('single')"
              />
              <span class="checkmark"></span>
            </label>
            <label class="container-radio">
              Over time
              <input
                type="radio"
                id="option-overtime"
                name="injurylength"
                (change)="handleInjuryType('overtime')"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-assessment *ngIf="showAssessment"></app-assessment>

<section *ngIf="!showAssessment" id="buttons-overtime">
  <div class="container">
    <div class="row">
      <div class="col">
        <!--actions -->
        <div class="py-3">
          <a
            class="btn-outline-blue btn-md btn mr-2 mb-2"
            [routerLink]="['/eligibilityQuizInstructions']"
            >Back</a
          >
          <a
            *ngIf="showButton"
            class="mb-2 btn-blue btn-md btn"
            [routerLink]="['/eligibilityQuizChannels']"
            >Continue</a
          >
        </div>
      </div>
    </div>
  </div>
</section>

<section class="">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <hr />
        <div>
          <strong
            >Have a question about reporting your injury? Please call our Teleclaim team at
            <a href="tel:6042318888">604.231.8888</a> (or toll-free at
            <a href="tel:18889675377">1.888.967.5377</a>).</strong
          >
        </div>
      </div>
    </div>
  </div>
</section>
