import { HttpResponse, delay, http } from 'msw';
import { RuwMessage, SubmitRuwMessageResponse } from 'src/app/swagger-api/swagger-api';

import { baseUrl } from './consts';

export default [
  http.post(`${baseUrl}/Ruw/SubmitCase1`, () => {
    return new HttpResponse(null, { status: 500, statusText: 'Internal Server Error' });
  }),

  http.post(`${baseUrl}/Ruw/SubmitCase`, async (reqResolver) => {
    delay(1000);
    const ruwMessage = (await reqResolver.request.json()) as RuwMessage;
    ruwMessage.contactInfo.firstName = ruwMessage.contactInfo.firstName ?? 'John';
    ruwMessage.contactInfo.lastName = ruwMessage.contactInfo.lastName ?? 'Doe';
    ruwMessage.incidentDetails.description = 'This is a test description.';

    return HttpResponse.json({
      data: ruwMessage,
      referenceId: '20040923001234',
    } as SubmitRuwMessageResponse);
  }),
];
