import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import urls from 'src/app/strings/urls.json';

@Component({
  selector: 'app-eligibility-quiz-instructions',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './eligibility-quiz-instructions.component.html',
  styleUrls: ['./eligibility-quiz-instructions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EligibilityQuizInstructionsComponent {
  public urls = urls;
  constructor() {}
}
