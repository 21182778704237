import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LetDirective, PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';
import {
  AppTxtJson,
  IAppInsightsService,
  ServiceLocator,
  WsbcAllModule,
  WsbcGoogleTagManagerModule,
} from '@wsbc/ux-lib';
import { AuthService } from '@wsbc/ux-lib-security';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  EnvironmentJson,
  FeatureFlagsJson,
  IFeatureFlags,
} from 'src/environments/environment.model';

import { AppCommonModule } from './app-common/app-common.module';
import { AppRoutingModule } from './app-routing.module';
import { AppInsightsService, APP_INSIGHT_CONFIG } from './app-services/app-insights-service';
import { QueryParamsService } from './app-services/query-params-service';
import { AppComponent } from './app.component';
import { RootStoreModule } from './store/root-store.module';
import { API_BASE_URL } from './swagger-api/swagger-api';

function initAuthService(
  featureFlagsJson: IFeatureFlags,
  appTxtJson: any,
  authService: AuthService,
  store: Store,
) {
  return () =>
    new Promise((resolve) => {
      authService.initialize().then(
        () => {
          authService.user.pipe(take(1)).subscribe((user: any) => {
            console.log('authenticated as', user);
            environment.onAuthenticationSuccess(user, appTxtJson, featureFlagsJson, store);
            resolve(void 0);
          });
        },
        (err) => {
          environment.onAuthenticationFail(err, appTxtJson, store);
          resolve(void 0);
        },
      );
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    AppRoutingModule,
    RootStoreModule,
    PushPipe,
    LetDirective,
    BsDatepickerModule.forRoot(),
    WsbcAllModule,
    WsbcGoogleTagManagerModule,

    environment.additionalModules,
    AppCommonModule,
    CommonModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (queryParamsService: QueryParamsService) => () =>
        queryParamsService.setQueryParams(),
      deps: [QueryParamsService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initAuthService,
      multi: true,
      deps: [FeatureFlagsJson, AppTxtJson, AuthService, Store],
    },
    environment.additionalProviders,

    {
      provide: API_BASE_URL,
      useFactory: (envJson: any) => envJson.apiUrl,
      deps: [EnvironmentJson],
    },
    { provide: IAppInsightsService, useClass: AppInsightsService },
    {
      provide: APP_INSIGHT_CONFIG,
      useFactory: (envJson: any) => envJson.applicationInsights,
      deps: [EnvironmentJson],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector, router: Router, appInsightService: IAppInsightsService) {
    // Create global Service Injector for app-insights-decorator.
    ServiceLocator.injector = this.injector;

    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        appInsightService.startNavigationEvent(event.url);
      } else if (event instanceof NavigationEnd) {
        appInsightService.endNavigationEvent(event.url);
        appInsightService.logPageView(`${event.url}`, event.urlAfterRedirects.toString());
      }
    });
  }
}
