import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { AppInsightsService } from '../../app-services/app-insights-service';
import { QueryParamsService, ValidQueryParams } from '../../app-services/query-params-service';
import { UserDataService } from '../../app-services/user-data-service';

@Component({
  selector: 'app-eligibility-quiz-injury',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './eligibility-quiz-injury.component.html',
  styleUrls: ['./eligibility-quiz-injury.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EligibilityQuizInjuryComponent implements OnInit {
  constructor(
    private router: Router,
    private location: Location,
    private logger: AppInsightsService,
    private userDataService: UserDataService,
    private queryParamsService: QueryParamsService,
  ) {}

  queryString: string = '';

  ngOnInit() {
    this.logger.TrackEvent('EligibilityQuiz-Entered');
    this.queryString = this.queryParamsService.getQueryParam(ValidQueryParams.from) ?? '';
  }

  onTileClick(eventName: string, target: string, setData: any = null) {
    this.logger.TrackEvent(eventName, { from: this.queryString });
    if (setData) {
      this.userDataService.setData(setData.key, setData.value);
    }
    this.router.navigate([target]);
  }

  public goBack(): void {
    this.location.back();
  }
}
