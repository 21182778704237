import * as i0 from '@angular/core';
import { Injectable, InjectionToken, RendererFactory2, Inject } from '@angular/core';
import { ReplaySubject, BehaviorSubject, of, EMPTY } from 'rxjs';
import { MSAL_GUARD_CONFIG, MsalService } from '@azure/msal-angular';
import { UserManager } from 'oidc-client';
import * as i2 from '@angular/router';
import { Router, ActivatedRoute } from '@angular/router';
import { debounceTime, take, switchMap, tap } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
class MsalAuthService {
  constructor(
  // NOTE: dependence injection via constructor is not working as expected due to timing issue
  //       use injector as shown below
  injector) {
    this.injector = injector;
    this._isAuthenticated = new ReplaySubject(1);
    this.isAuthenticated = this._isAuthenticated.asObservable();
    this._user = new ReplaySubject(1);
    this.user = this._user.asObservable();
    this._idToken = new ReplaySubject(1);
    this.idToken = this._idToken.asObservable();
    this._exitUrl = new ReplaySubject();
    this.exitUrl = this._exitUrl.asObservable();
  }
  initialize(options) {
    const usePopup = options?.usePopup ?? false;
    this.guardConfig = this.injector.get(MSAL_GUARD_CONFIG);
    this.msalService = this.injector.get(MsalService);
    const setAuthenticationResult = result => {
      this._isAuthenticated.next(true);
      const token = result.idToken;
      this.roles = result.idTokenClaims.roles;
      this._idToken.next(token);
      this._exitUrl.next('https://www.worksafebc.com/en');
    };
    return this.msalService.instance.initialize().then(() => {
      const accounts = this.msalService.instance.getAllAccounts();
      const scopes = this.guardConfig.authRequest.scopes;
      if (accounts.length > 0) {
        return new Promise((resolve, reject) => {
          const account = accounts[0];
          this._user.next(account);
          this.msalService.instance.acquireTokenSilent({
            account,
            scopes
          }).then(result => {
            setAuthenticationResult(result);
            console.log('initialized - account is', account);
            resolve();
          }, err => reject(err));
        });
      } else if (usePopup) {
        return new Promise((resolve, reject) => {
          this.msalService.instance.loginPopup({
            scopes,
            prompt: 'select_account'
          }).then(result => {
            setAuthenticationResult(result);
            const account = this.msalService.instance.getAllAccounts()[0];
            this._user.next(account);
            console.log('initialized - account is', account);
            resolve();
          }, err => {
            reject(err);
          });
        });
      } else {
        return this.msalService.instance.handleRedirectPromise().then(() => this.msalService.instance.loginRedirect());
      }
    });
  }
  redirectToSignInPage() {
    this.msalService.loginRedirect();
  }
  signOut() {
    this.msalService.logout();
  }
  /** @nocollapse */
  static {
    this.ɵfac = function MsalAuthService_Factory(t) {
      return new (t || MsalAuthService)(i0.ɵɵinject(i0.Injector));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MsalAuthService,
      factory: MsalAuthService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MsalAuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i0.Injector
  }], null);
})();
const SECURITY_SETTINGS = new InjectionToken('SECURITY_SETTINGS');
var SecurityRoutesEnum;
(function (SecurityRoutesEnum) {
  SecurityRoutesEnum["SignInCallback"] = "signin-callback";
})(SecurityRoutesEnum || (SecurityRoutesEnum = {}));
class IdamAuthService {
  constructor(securitySettings, injector) {
    this.injector = injector;
    this.REDIRECT_PATH_KEY = 'redirectPath';
    this._user = new BehaviorSubject(null);
    this.user = this._user.asObservable();
    this._idToken = new BehaviorSubject(null);
    this.idToken = this._idToken.asObservable();
    this._isAuthenticated = new BehaviorSubject(false);
    this.isAuthenticated = this._isAuthenticated.asObservable();
    this._exitUrl = new ReplaySubject();
    this.exitUrl = this._exitUrl.asObservable();
    this.roles = [];
    const rendererFactory = this.injector.get(RendererFactory2);
    this._renderer = rendererFactory.createRenderer(null, null);
    this._securitySettings = securitySettings;
    const scopes = securitySettings.scopes && securitySettings.scopes.length ? securitySettings.scopes : ['wsbcprofile'];
    this._userManager = new UserManager({
      authority: securitySettings.stsAuthority,
      client_id: securitySettings.clientId,
      redirect_uri: `${securitySettings.clientRoot}/${SecurityRoutesEnum.SignInCallback}`,
      scope: `openid ${scopes.join(' ')}`,
      response_type: 'code'
    });
    this.user.subscribe(user => {
      const isAuthenticated = !!user && user.expired !== null && user.expired !== undefined && !user.expired;
      this._isAuthenticated.next(isAuthenticated);
      const idToken = !!user && !user.expired && user.id_token ? user.id_token : null;
      this._idToken.next(idToken);
      const exitUrl = user?.profile?.exiturl ?? "";
      this._exitUrl.next(exitUrl);
    });
    this._userManager.events.addUserLoaded(user => {
      this._user.next(user);
    });
    this._userManager.events.removeUserLoaded(user => {
      this._user.next(user);
    });
    this._userManager.startSilentRenew();
  }
  initialize() {
    return new Promise((resolve, reject) => {
      if (window.location.pathname === '/' + SecurityRoutesEnum.SignInCallback) {
        if (this.inIframe()) {
          this.completeSilentSignIn();
        } else {
          this.completeSignIn().then(() => resolve()).catch(() => reject());
        }
      } else {
        this.subscribeToQueryParams().then(() => resolve()).catch(() => reject());
      }
    });
  }
  redirectToSignInPage(redirectPath) {
    if (redirectPath) {
      // Before redirecting to the sign in page, store the path so the application can return to this path after signing in
      sessionStorage.setItem(this.REDIRECT_PATH_KEY, redirectPath);
    }
    this._userManager.signinRedirect();
  }
  signOut() {
    const token = this._idToken.getValue();
    if (token) {
      const decoded_token = jwt_decode(token);
      if (decoded_token.exiturl) {
        setTimeout(() => {
          window.open(decoded_token.exiturl, '_self');
        });
      }
    }
  }
  completeSignIn() {
    return new Promise((resolve, reject) => {
      this._userManager.signinRedirectCallback().then(user => {
        this._user.next(user);
        this.handleSigninRedirectCallback();
        resolve();
      }).catch(error => {
        if (error.message === 'Internal Server Error (500)') {
          window.open(this._securitySettings.errorUrl, '_self');
        } else {
          // When there is an error, redirect to the signin page will either fix it or request the login credentials again
          this.redirectToSignInPage();
        }
        reject();
      });
    });
  }
  handleSigninRedirectCallback() {
    // Once login is successful, we want to start listening for beforeunload to clear the user
    // Also listen for session storage changes - for when user manually clears the session storage
    this.startListeningToWindowEvents();
    this.navigateToRedirectPathFromSignInCallback();
  }
  navigateToRedirectPathFromSignInCallback() {
    const redirectPath = sessionStorage.getItem(this.REDIRECT_PATH_KEY) ? sessionStorage.getItem(this.REDIRECT_PATH_KEY) : '/';
    // After retrieving the redirectUrl, clear it so that it can't be used again.
    sessionStorage.removeItem(this.REDIRECT_PATH_KEY);
    // Replace the signin-callback so when the user clicks back it will not go back to signin-callback (FF)
    history.replaceState(null, '', '/');
    this.injector.get(Router).navigateByUrl(redirectPath);
  }
  completeSilentSignIn() {
    return this._userManager.signinSilentCallback();
  }
  subscribeToQueryParams() {
    return new Promise((resolve, reject) => {
      const activatedRoute = this.injector.get(ActivatedRoute);
      const sub = activatedRoute.queryParams.pipe(debounceTime(200)).subscribe(async params => {
        // If no code in url check for authenticated user
        // if no user found, redirect to sign in page
        // else if user found, complete promise
        if (!params['code']) {
          let user = this._user.getValue();
          if (!user || user && user.expired) {
            user = await this._userManager.getUser();
            this._user.next(user);
          }
          if (!user || user && user.expired) {
            sub.unsubscribe();
            if (!this._securitySettings.allowAnonymous) {
              this.redirectToSignInPage(window.location.pathname + window.location.search);
              reject();
            }
            resolve();
          } else {
            sub.unsubscribe();
            // Once login is successful, we want to start listening for pagehide to clear the user
            // Also listen for session storage changes - for when user manually clears the session storage
            this.startListeningToWindowEvents();
            resolve();
          }
        }
      });
    });
  }
  startListeningToWindowEvents() {
    this.listenToUnloadAndClearSession();
    this.listenToSessionStorage();
  }
  listenToUnloadAndClearSession() {
    // On Chrome console logs, breakpoints, etc. don't show up, but this event does fire
    this._renderer.listen(window, 'pagehide', () => {
      // Only clear session if not in iframe.  Iframe is doing silent token renewals.
      if (!this.inIframe()) {
        this.clearSession();
      }
    });
  }
  listenToSessionStorage() {
    this.storageEventListener = this._renderer.listen(window, 'storage', () => {
      // Attempt to update the user when there is storage change - This is important for when a user manually clears storage through browser
      this._userManager.getUser().then(user => {
        this._user.next(user);
      }).catch(() => {
        this._user.next(null);
      });
    });
  }
  clearSession() {
    // Unlisten to storage event listener before clearing to avoid infinite loop
    if (this.storageEventListener) {
      this.storageEventListener();
    }
    // Session clearing
    this._user.next(null);
    this._userManager.removeUser();
  }
  inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
  /** @nocollapse */
  static {
    this.ɵfac = function IdamAuthService_Factory(t) {
      return new (t || IdamAuthService)(i0.ɵɵinject(SECURITY_SETTINGS), i0.ɵɵinject(i0.Injector));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: IdamAuthService,
      factory: IdamAuthService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IdamAuthService, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [SECURITY_SETTINGS]
    }]
  }, {
    type: i0.Injector
  }], null);
})();

// the purpose of this class is to provide uniform handling for any application with/without
// authentication.
class AnonymousAuthService {
  constructor() {
    this.initialize = () => Promise.resolve();
    this.isAuthenticated = of(true);
    this.user = of({
      userName: 'anonymous',
      isAnonymous: true
    });
    this.idToken = of('');
    this.exitUrl = of('https://www.worksafebc.com');
    this.redirectToSignInPage = () => {};
    this.signOut = () => {};
    this.roles = ['anonymous'];
  }
}
class IdamAuthenticationGuard {
  constructor(authService) {
    this.authService = authService;
  }
  canLoad(route) {
    this.authService.isAuthenticated.pipe(take(1)).subscribe(signedIn => {
      if (!signedIn) {
        this.authService.redirectToSignInPage();
      }
    });
    return true;
  }
  canActivate(route, state) {
    this.authService.isAuthenticated.pipe(take(1)).subscribe(signedIn => {
      if (!signedIn) {
        this.authService.redirectToSignInPage();
      }
    });
    return true;
  }
  /** @nocollapse */
  static {
    this.ɵfac = function IdamAuthenticationGuard_Factory(t) {
      return new (t || IdamAuthenticationGuard)(i0.ɵɵinject(IdamAuthService));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: IdamAuthenticationGuard,
      factory: IdamAuthenticationGuard.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IdamAuthenticationGuard, [{
    type: Injectable
  }], () => [{
    type: IdamAuthService
  }], null);
})();
class AuthService {}
var SecurityTypeEnum;
(function (SecurityTypeEnum) {
  SecurityTypeEnum["MSAL"] = "msal";
  SecurityTypeEnum["ADAL"] = "adal";
  SecurityTypeEnum["IDAM"] = "idam";
})(SecurityTypeEnum || (SecurityTypeEnum = {}));
class TokenInterceptor {
  constructor(_authService, _router) {
    this._authService = _authService;
    this._router = _router;
  }
  intercept(request, next) {
    return this._authService.idToken.pipe(take(1), switchMap(token => {
      if (token) {
        return this.bindTokenAndSubmitRequest(request, token, next);
      } else {
        this._authService.redirectToSignInPage();
        return EMPTY;
      }
    }));
  }
  handleError(error) {
    if (error && error.status === 401) {
      // Try refreshing user/token and making request again.  If null, redirect to sign in page.
      this._authService.redirectToSignInPage();
    }
  }
  bindTokenAndSubmitRequest(request, token, next) {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
    return next.handle(request).pipe(tap(() => null, error => {
      this.handleError(error);
    }));
  }
  /** @nocollapse */
  static {
    this.ɵfac = function TokenInterceptor_Factory(t) {
      return new (t || TokenInterceptor)(i0.ɵɵinject(AuthService), i0.ɵɵinject(i2.Router));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TokenInterceptor,
      factory: TokenInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TokenInterceptor, [{
    type: Injectable
  }], () => [{
    type: AuthService
  }, {
    type: i2.Router
  }], null);
})();

/*
* Public API Surface of ux-lib-security
*/
// security

/**
 * Generated bundle index. Do not edit.
 */

export { AnonymousAuthService, AuthService, IdamAuthService, IdamAuthenticationGuard, MsalAuthService, SECURITY_SETTINGS, SecurityRoutesEnum, SecurityTypeEnum, TokenInterceptor };
