import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { environment } from 'src/environments/environment';

import { AppAuthorizationModule } from './app-authorization/app-authorization.module';
import { AppSessionModule } from './app-session/app-session.module';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(
      {
        router: routerReducer,
      },
      {
        runtimeChecks: environment.ngrxRuntimeChecks ?? {},
      },
    ),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot(),
    AppSessionModule,

    AppAuthorizationModule,
  ],
})
export class RootStoreModule {}
