import { Store } from '@ngrx/store';
import { AuthService, AnonymousAuthService } from '@wsbc/ux-lib-security';
import { RECAPTCHA_SETTINGS, RecaptchaComponent, RecaptchaSettings } from 'ng-recaptcha';
import { applicationStarts } from 'src/app/store/app-session/app-session.actions';

import {
  EnvironmentJson,
  IEnvironment,
  IEnvironmentJson,
  IFeatureFlags,
} from './environment.model';

// https://github.com/DethAriel/ng-recaptcha/issues/123
RecaptchaComponent.prototype.ngOnDestroy = function () {};

export const environment: IEnvironment = {
  production: true,
  wiirAnonUrl: 'https://wiiranon.online.worksafebc.com/createreport.aspx',
  wiirUrl: 'https://wiir.online.worksafebc.com/createreport.aspx',
  wrioUrl: 'https://workerinjuryreport.online.worksafebc.com',
  workerDashboardUrl: 'https://op.online.worksafebc.com',
  workerBcOnlineUrl: 'https://www.worksafebc.com',
  fixConfiguration: (environmentJson: IEnvironmentJson) => {
    return environmentJson;
  },
  addGuards: (routes) => routes,
  additionalModules: [],
  additionalProviders: [
    AnonymousAuthService,
    {
      provide: AuthService,
      useExisting: AnonymousAuthService,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useFactory: (envJson: any) =>
        ({ siteKey: envJson.google_recaptcha_site_key } as RecaptchaSettings),
      deps: [EnvironmentJson],
    },
  ],
  onAuthenticationSuccess: function (
    user: any,
    appTxt: any,
    featureFlags: IFeatureFlags,
    store: Store,
  ): void {
    window.__wsbc_username = 'AnonymousUser';
    store.dispatch(
      applicationStarts({ user: { ...user, roles: ['AnonymousUser'] }, appTxt, featureFlags }),
    );
  },

  // eslint-disable-next-line no-unused-vars
  onAuthenticationFail: function (err: any, appTxt: any, store: Store): void {
    // noop
  },
};
