<wsbc-modal-dialog
  [title]="title"
  [showFooter]="true"
  [showPrimaryBtn]="false"
  [showSecondaryBtn]="false"
  [titleId]="'dialog-title-id'"
  (closeBtnClicked)="closeBtnClicked()"
>
  <div class="row">
    <div class="col-lg-6">
      <a id="btn-signin" class="cardlink" (click)="wait = true" [href]="wiirUrl">
        <div class="infocard bg-white my-2">
          <h3 class="text-dark pt-0">Log in to access a saved report</h3>
          <p>Select this option if you’ve already started an injury report and want to continue.</p>
        </div>
      </a>
    </div>
    <div class="col-lg-6">
      <a id="btn-guest" class="cardlink" (click)="wait = true" [href]="wiirAnonUrl">
        <div class="infocard bg-white my-2">
          <h3 class="text-dark pt-0">Start a new report</h3>
          <p>Select this option if you’re starting a new report of injury.</p>
        </div>
      </a>
    </div>
  </div>
  <div *ngIf="wait" class="row">
    <div class="position-absolute"><wsbc-loading-bouncer></wsbc-loading-bouncer></div>
  </div>
</wsbc-modal-dialog>
