<div
  wsbc-header
  *ngrxLet="isAppInitialized$ as isAppInitialized"
  [isFluidContainer]="false"
  (headerClassChanged)="onHeaderClassChanged($event)"
>
  <ng-container wsbc-navbar-content>
    <wsbc-top-navbar
      *ngIf="isAppInitialized"
      [exitUrl]="exitUrl"
      [menuItems]="menuItems"
    ></wsbc-top-navbar>
  </ng-container>
  <ng-container page-title *ngIf="isAppInitialized"></ng-container>
  <ng-container main-content>
    <div class="space" *ngIf="!isAppInitialized"></div>
    <wsbc-loading-bouncer *ngIf="!isAppInitialized"></wsbc-loading-bouncer>
    <router-outlet *ngIf="isAppInitialized"></router-outlet>
  </ng-container>
  <footer wsbc-footer></footer>
</div>
