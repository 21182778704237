import { Permission } from './permissions';
import { Role } from './roles';

export type IRoleToPermissionMap = {
  [key in Role]: Permission[];
};

export const roleToPermissionMap: IRoleToPermissionMap = {
  [Role.InternalAdmin]: [
    Permission.canManageTest,
    Permission.canSelectIab,
    Permission.canUseMiscellaneousWebFrontEndFunctions,
    Permission.canEditWorker,
    Permission.canManageWorker,
    Permission.canViewInternalReport,
    Permission.canEditSubmittedTest,
    Permission.canManageInternalComments,
    Permission.canViewInternalComments,
    Permission.canDeleteOwnedTest,
    Permission.canDeleteAllTest,
    Permission.canViewAllHearingTest,
  ],
  [Role.InternalUser]: [Permission.canViewEmployerReports],
  [Role.ExternalEmployer]: [Permission.canViewEmployerReports],
  [Role.ExternalEmployee]: [Permission.canViewEmployerReports],
  [Role.AnonymousUser]: [Permission.canViewAllHearingTest],
};
