import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';
import { ModalDialogSizeEnum, ModalDialogTypeEnum } from '@wsbc/ux-lib';
import { WsbcModalDialogModule } from '@wsbc/ux-lib';
import { ModalModule } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';

import { AppInsightsService } from '../../app-services/app-insights-service';
import {
  QueryParamsService,
  ValidFromQueryParams,
  ValidQueryParams,
} from '../../app-services/query-params-service';
import { UserDataService } from '../../app-services/user-data-service';

@Component({
  selector: 'app-assessment',
  standalone: true,
  imports: [RouterModule, CommonModule, FormsModule, WsbcModalDialogModule, ModalModule],
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentComponent {
  constructor(
    private router: Router,
    private queryParamsService: QueryParamsService,
    private logger: AppInsightsService,
    private userDataService: UserDataService,
  ) {}

  selectedOption: string | undefined;
  missedTimeAfterInjury: boolean = false;

  continueButtonEnabled: boolean = true;
  notMissedAnyTime: boolean = false;
  missedTimeDuringtheDay: boolean = false;

  showAssessment = false;
  showButton: boolean = false;
  size: ModalDialogSizeEnum = ModalDialogSizeEnum.MEDIUM;
  title = 'Log in to continue';
  visible = false;
  isPrimaryBtnDisabled = false;
  isSecondaryBtnDisabled = false;

  isLeft = true;
  get mode(): ModalDialogTypeEnum {
    return this.isLeft ? ModalDialogTypeEnum.LeftBlade : ModalDialogTypeEnum.RightBlade;
  }

  onCloseBtnClicked() {
    this.visible = false;
  }

  onContinueWrapperClicked() {
    if (this.continueButtonEnabled) {
      const sectionToScroll = document.getElementById('selectionToggle');
      if (sectionToScroll) {
        sectionToScroll.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      this.openDialogBox();
    }
  }

  openDialogBox() {
    if (
      this.missedTimeAfterInjury ||
      ((this.notMissedAnyTime || this.missedTimeDuringtheDay) &&
        this.selectedOption == 'yes-anticipate')
    ) {
      this.router.navigate(['eligibilityQuizChannels']);
      return;
    }

    if (this.isUserLoggedIn()) {
      // skip showing the modal:
      this.onContinueBtnClicked();
    } else {
      this.size = ModalDialogSizeEnum.MEDIUM;
      this.visible = true;
    }
  }

  onContinueBtnClicked() {
    this.logger.TrackEvent('EligibilityQuiz-Solved');

    const injuryType = this.userDataService.getData('injuryType');
    const missedTimeFromWork = {
      notMissed: this.userDataService.getData('missedTimeFromWorkNM'),
      missedDayShift: this.userDataService.getData('missedTimeFromWorkMSD'),
    };
    const anticipatedMissingTime = this.userDataService.getData('anticipatedMissingTime');

    const EQQAObj = {
      injuryType: injuryType,
      missedTimeFromWork: missedTimeFromWork,
      anticipatedMissingTime: anticipatedMissingTime,
    };

    const EQQAString = JSON.stringify(EQQAObj);
    const EQQAbase64 = btoa(EQQAString);
    this.queryParamsService.setParams({ EQQA: EQQAbase64 });

    const queryString = this.queryParamsService.getQueryParamsString();

    const wrioRedirectUrl = queryString
      ? `${environment.wrioUrl}?${queryString}`
      : environment.wrioUrl;

    window.location.href = wrioRedirectUrl;
  }

  onSelectionChange(option: string) {
    let anticipatedMissingTime: string = '';
    switch (option) {
      case 'yes-anticipate':
        anticipatedMissingTime = 'Yes';
        break;
      case 'no-anticipate':
        anticipatedMissingTime = 'No';
        break;
      case 'notsure-anticipate':
        anticipatedMissingTime = 'NotSure';
        break;
    }
    this.userDataService.setData('anticipatedMissingTime', anticipatedMissingTime);

    this.selectedOption = option;
    this.isContinueButtonEnabled();
  }

  isContinueButtonEnabled() {
    this.continueButtonEnabled = !this.isAnyCheckboxChecked() || this.selectedOption === undefined;
  }

  onCheckBoxChecked() {
    this.userDataService.setData('missedTimeFromWorkNM', this.notMissedAnyTime ? 'Yes' : 'No');
    this.userDataService.setData(
      'missedTimeFromWorkMSD',
      this.missedTimeDuringtheDay ? 'Yes' : 'No',
    );

    this.isContinueButtonEnabled();
    if (this.missedTimeAfterInjury) {
      this.selectedOption = undefined;
      this.continueButtonEnabled = false;
    }
  }

  isAnyCheckboxChecked(): boolean {
    return (this.notMissedAnyTime || this.missedTimeDuringtheDay) && !this.missedTimeAfterInjury;
  }

  isUserLoggedIn(): boolean {
    const fromQueryParam = this.queryParamsService.getQueryParam(ValidQueryParams.from);
    // if user is originating from either wd or osd flows consider them authenticated:
    return [ValidFromQueryParams.osd, ValidFromQueryParams.wd].includes(
      fromQueryParam as ValidFromQueryParams,
    );
  }

  showAnticipateBlock(): boolean {
    if ((this.notMissedAnyTime || this.missedTimeDuringtheDay) && !this.missedTimeAfterInjury) {
      return true;
    }
    return false;
  }

  showPleaseNoteBlock(): boolean {
    return (
      this.selectedOption !== 'yes-anticipate' &&
      this.selectedOption !== undefined &&
      this.showAnticipateBlock()
    );
  }
}
