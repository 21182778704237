import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ApplicationInsights, IConfig, IConfiguration } from '@microsoft/applicationinsights-web';
import { IAppInsightsService } from '@wsbc/ux-lib';

export const APP_INSIGHT_CONFIG = new InjectionToken<IConfiguration & IConfig>(
  'APP_INSIGHT_CONFIG',
);

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService extends IAppInsightsService {
  private appInsights: ApplicationInsights;

  constructor(
    @Inject(APP_INSIGHT_CONFIG)
    appInsightsConfig: IConfiguration & IConfig,
  ) {
    appInsightsConfig.disableCorrelationHeaders = false;
    console.log('app-insight config', appInsightsConfig);
    const appInsights = new ApplicationInsights({ config: appInsightsConfig });

    super(appInsights);
    this.appInsights = appInsights;
    appInsights.loadAppInsights();

    // This allows us to customize the duration used in TrackPageView
    appInsights.config.overridePageViewDuration = true;

    appInsights.trackPageView();
  }

  /**
   * Log a user action or other occurrence.
   * @param   name    A string to identify this event in the portal.
   * @param   properties  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
   */
  public TrackEvent(name: string, properties?: { [name: string]: string }): void {
    this.appInsights.trackEvent({ name: name }, properties);
  }
}
