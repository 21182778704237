<!-- Application Title -->
<section>
  <div class="container">
    <div class="row pt-4">
      <div class="col-md-12">
        <h1>Report your injury or illness</h1>
      </div>
    </div>
  </div>
</section>

<!--Intro Text-->
<section>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 style="">Before you begin</h2>
        <p>
          We’re here to help people who are injured at work get the medical care and services they
          need to support their recovery and return to work. This tool will guide you through
          reporting a workplace injury or disease to WorkSafeBC.
        </p>
      </div>
    </div>

    <div id="block-infocards" class="row">
      <!-- How Long -->
      <div class="col-lg-6 pt-4 pb-3">
        <article class="grid-iconbox">
          <img
            class="px-2 landericon"
            src="assets/img/landericon-howlong.svg"
            alt="Reimbursements"
          />
          <div>
            <h3 class="text-dark pt-1">How long will it take?</h3>
            <div>
              You’ll need approximately 20 minutes to complete your report. To protect your
              information, your report will close if you do not click or type anything for 20
              minutes.
            </div>
          </div>
        </article>
      </div>

      <!-- Teleclaim -->
      <div class="col-lg-6 pt-4 pb-3">
        <article class="grid-iconbox">
          <img
            class="px-2 landericon"
            src="assets/img/landericon-teleclaim.svg"
            alt="Reimbursements"
          />
          <div>
            <h3 class="text-dark pt-1">Prefer to speak with someone?</h3>
            <div>
              <a [href]="urls.relatedClaimUrl" target="_blank">Call our Teleclaim team</a>
              if you'd like to speak with someone in your preferred language.
            </div>
          </div>
        </article>
      </div>

      <!-- Claim -->
      <div class="col-lg-6 pt-4 pb-3">
        <article class="grid-iconbox">
          <img class="px-2 landericon" src="assets/img/landericon-claim.svg" alt="Reimbursements" />
          <div>
            <h3 class="text-dark pt-1">Have a related claim?</h3>
            <div>
              If you’re looking to report an injury or illness related to a previous WorkSafeBC
              claim, please
              <a [href]="urls.relatedClaimUrl" target="_blank">call our Teleclaim team</a>.
            </div>
          </div>
        </article>
      </div>

      <!-- Support -->
      <div class="col-lg-6 pt-4 pb-3">
        <article class="grid-iconbox">
          <img
            class="px-2 landericon"
            src="assets/img/landericon-support.svg"
            alt="Reimbursements"
          />
          <div>
            <h3 class="text-dark pt-1">Need support?</h3>
            <div>
              Call our Crisis Support Line toll-free at
              <a href="tel:18006242928">1.800.624.2928</a> if you or your family are in emotional
              crisis.
            </div>
          </div>
        </article>
      </div>
    </div>

    <!--What you'll need'-->
    <div class="row mb-3">
      <div class="col-md-12">
        <div class="py-1">
          <h2>What you’ll need</h2>

          <div class="grid-3 mt-3 mb-3">
            <!-- PHN -->
            <div class="py-3 d-flex justify-content-start align-items-start lightcard">
              <img class="landericon" src="assets/img/lander-phn.svg" />
              <div class="pl-2">
                <b>Personal Health Number (PHN)</b>
                <div>From your BC’s Services Card and/or driver’s licence</div>
              </div>
            </div>
            <!-- First Medical Visit -->
            <div class="py-3 d-flex justify-content-start align-items-start lightcard">
              <img class="landericon" src="assets/img/lander-medical.svg" />
              <div class="pl-2">
                <b>First medical visit</b>
                <div>Details of who you saw and what treatment you received</div>
              </div>
            </div>
            <!-- Employer Contact -->
            <div class="py-3 d-flex justify-content-start align-items-start lightcard">
              <img class="landericon" src="assets/img/lander-contract.svg" />
              <div class="pl-2">
                <b>Employer contact</b>
                <div>Employer’s name, address, and phone number</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--Main Action -->
<section class="mt-4" style="">
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex align-items-center button-container">
        <a class="btn-outline-blue btn-md btn mr-3" [routerLink]="['/eligibilityQuizInjury']"
          >Back</a
        >
        <a class="btn btn-md btn-blue me-2" [routerLink]="['/eligibilityQuizBeforeYouBegin']"
          >Continue</a
        >
      </div>
    </div>
  </div>
</section>
