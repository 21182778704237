import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { INavMenuItem } from '@wsbc/ux-lib';
import { environment } from 'src/environments/environment';

import {
  QueryParamsService,
  ValidFromDraftsQueryParams,
  ValidFromQueryParams,
  ValidQueryParams,
} from './app-services/query-params-service';
import { TopMenuClassChanged } from './store/app-session/app-session.actions';
import { isAppInitialized } from './store/app-session/app-session.selectors';
import urls from './strings/urls.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'app';
  readonly urls = urls;
  exitUrl = '';

  menuItems: INavMenuItem[] = [];

  isAppInitialized$ = this.store.select(isAppInitialized);

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private queryParamsService: QueryParamsService,
  ) {}

  ngOnInit(): void {
    const fromQueryParam = this.queryParamsService.getQueryParam(ValidQueryParams.from) || '';
    const fromDraftsQueryParam =
      this.queryParamsService.getQueryParam(ValidQueryParams.fromDrafts) || '';
    if (
      [ValidFromQueryParams.wd, ValidFromQueryParams.osd].includes(
        fromQueryParam as ValidFromQueryParams,
      ) ||
      fromDraftsQueryParam === ValidFromDraftsQueryParams.true
    ) {
      this.exitUrl = environment.workerDashboardUrl;
    } else {
      this.exitUrl = environment.workerBcOnlineUrl;
    }
  }

  onHeaderClassChanged(className: any) {
    this.store.dispatch(TopMenuClassChanged({ className }));
  }
}
