import { CommonModule, Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  QueryParamsService,
  ValidFromQueryParams,
  ValidQueryParams,
} from 'src/app/app-services/query-params-service';
import urls from 'src/app/strings/urls.json';
import { environment } from 'src/environments/environment';

import { AppInsightsService } from '../../app-services/app-insights-service';
import { ContinueOnlineModalComponent } from './continue-online-modal/continue-online-modal.component';

@Component({
  selector: 'app-eligibility-quiz-channels',
  standalone: true,
  imports: [RouterModule, CommonModule, ContinueOnlineModalComponent],
  templateUrl: './eligibility-quiz-channels.component.html',
  styleUrls: ['./eligibility-quiz-channels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EligibilityQuizChannelsComponent {
  constructor(
    private location: Location,
    private logger: AppInsightsService,
    private queryParamService: QueryParamsService,
  ) {}

  @ViewChild(ContinueOnlineModalComponent) continueOnlineModal!: ContinueOnlineModalComponent;

  public urls = urls;
  wiirAnonUrl = environment.wiirAnonUrl;
  wiirUrl = environment.wiirUrl;

  onCreateReportLinkClick(event: MouseEvent): void {
    event.preventDefault();
    this.logger.TrackEvent('EligibilityQuiz-Solved');
    switch (this.queryParamService.getQueryParam(ValidQueryParams.from)) {
      case ValidFromQueryParams.wd:
        window.location.href = this.wiirUrl;
        break;
      case ValidFromQueryParams.osd:
        window.location.href = this.wiirAnonUrl;
        break;
      default:
        this.continueOnlineModal.show();
        break;
    }
  }

  public goBack(): void {
    this.location.back();
  }
}
