import { Injectable } from '@angular/core';

export enum ValidFromQueryParams {
  wd = 'wd', // Worker portal
  osd = 'osd', // online service dashboard
  public = 'public', // Worker online services
}
export enum ValidQueryParams {
  from = 'from',
  fromDrafts = 'fromDrafts',
}

export enum ValidFromDraftsQueryParams {
  true = 'true',
  false = 'false',
}

@Injectable({
  providedIn: 'root',
})
/**
 * Manages query parameters for the application.
 *
 * This service captures and persists query parameters from the URL into sessionStorage
 * during app initialization. This ensures that important query parameters are retained
 * even if the user refreshes the page without query parameters in the URL.
 *
 * The service is particularly useful for maintaining the context of where the user came from
 * (e.g., worker dashboard, online service dashboard) and whether they're accessing drafts.
 *
 * Key features:
 * - Captures query parameters at app initialization (called in app.module.ts)
 * - Stores only valid query parameters as defined in ValidQueryParams enum
 * - Provides methods to retrieve stored query parameters
 * - Helps determine the exit URL based on the 'from' and 'fromDrafts' parameters
 *
 * Usage:
 * - The `setQueryParams` method is called during app initialization in app.module.ts
 * - The `getQueryParam` method is used in app.component.ts to determine the exit URL
 */
export class QueryParamsService {
  setParams(params: any): void {
    const sessionParams = JSON.parse(sessionStorage.getItem('queryParams') || '{}');
    Object.entries(params).forEach(([key, value]) => {
      sessionParams[key] = value;
    });
    sessionStorage.setItem('queryParams', JSON.stringify(sessionParams));
  }

  setQueryParams(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionParams: { [key: string]: any } = {};

    // Update session storage with current query parameters
    urlParams.forEach((value, key) => {
      if (ValidQueryParams[key as keyof typeof ValidQueryParams]) {
        sessionParams[key] = value;
      }
    });

    if (Object.keys(sessionParams).length) {
      sessionStorage.setItem('queryParams', JSON.stringify(sessionParams));
    }
  }

  getQueryParamsString(): string | null {
    const queryParams = JSON.parse(sessionStorage.getItem('queryParams') || '{}');
    const params: string[] = [];

    for (const [key, value] of Object.entries(queryParams)) {
      if (value !== null && value !== undefined) {
        params.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
      }
    }

    return params.length > 0 ? params.join('&') : null;
  }

  getQueryParam(key: string): string | null {
    const sessionParams = JSON.parse(sessionStorage.getItem('queryParams') || '{}');
    return sessionParams[key] || null;
  }
}
