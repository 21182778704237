import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import {
  WsbcLoadingBouncerModule,
  WsbcModalDialogComponent,
  WsbcModalDialogModule,
} from '@wsbc/ux-lib';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-continue-online-modal',
  standalone: true,
  imports: [NgIf, WsbcModalDialogModule, ModalModule, WsbcLoadingBouncerModule],
  templateUrl: './continue-online-modal.component.html',
  styleUrls: ['./continue-online-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContinueOnlineModalComponent {
  constructor(private modalService: BsModalService) {}
  @ViewChild(WsbcModalDialogComponent) modalComponent!: WsbcModalDialogComponent;
  modalRef!: BsModalRef;
  wiirAnonUrl = environment.wiirAnonUrl;
  wiirUrl = environment.wiirUrl;
  wait = false;
  title = 'Continue online';

  private addMediumModalClass() {
    // Note: since the modal is created using modal is created using modalService.show
    // the wsbc modal size parameter does not work
    // so we need to add the class to the modal when it is created

    const modal = document.querySelector('.modal');
    if (modal && !modal.classList.contains('medium-modal')) {
      modal.classList.add('medium-modal');
    }
  }

  closeBtnClicked() {
    this.modalService.hide(this.modalRef.id);
  }

  show() {
    this.modalRef = this.modalService.show(this.modalComponent.template, {
      backdrop: true,
      ignoreBackdropClick: false,
    });
    this.addMediumModalClass();
  }
}
