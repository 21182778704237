export enum Permission {
  // list of application permissions
  canManageTest,
  canSelectIab,
  canUseMiscellaneousWebFrontEndFunctions,
  canEditWorker,
  canManageWorker,
  canViewEmployerReports,
  canViewInternalReport,
  canEditSubmittedTest,
  canManageInternalComments,
  canViewInternalComments,
  canDeleteOwnedTest,
  canDeleteAllTest,
  canViewAllHearingTest,
}
