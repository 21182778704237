<!-- Application Title -->
<section>
  <div class="container">
    <div class="row pt-4">
      <div class="col-md-12">
        <h1>Report your injury or illness</h1>
      </div>
    </div>
  </div>
</section>
<!-- Getting started -->
<section>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 style="">Getting started</h2>
        <b>Which best describes the type of injury you are reporting?</b>
      </div>
      <div class="col-12">
        <p>
          If you have multiple injuries, you will have an opportunity to describe your injuries in
          more detail later.
        </p>
      </div>
    </div>
  </div>
</section>
<!-- Injury Types Tiles -->
<section class="d-none d-sm-block">
  <div class="container">
    <div class="row fadein">
      <!-- A Physical Injury -->
      <div class="col-lg-4 col-md-6" style="">
        <a
          id="btn-physicalx"
          class="cardlink"
          (click)="
            onTileClick('PhysicalInjuryClicked', '/eligibilityQuizInstructions', {
              key: 'injuryType',
              value: 'PhysicalInjury'
            })
          "
        >
          <div class="infocard bg-white my-2">
            <h3 class="text-dark pt-0">A physical injury</h3>
            <div>
              Such as a sprain, cut, fracture, smoke inhalation, concussion, bruise, burn (chemical
              or fire), hernia, or needlestick
            </div>
          </div>
        </a>
      </div>
      <!-- An Occupational Disease -->
      <div class="col-lg-4 col-md-6" style="">
        <a
          id=""
          class="cardlink"
          (click)="onTileClick('OccupationalDiseaseClicked', '/eligibilityQuizChannels')"
        >
          <div class="infocard bg-white my-2">
            <h3 class="text-dark pt-0">An occupational disease</h3>
            <div>
              Including exposure to a virus, such as HIV, a harmful substance, such as asbestos, or
              an irritant that causes a rash
            </div>
          </div>
        </a>
      </div>
      <!-- Hearing Loss -->
      <div class="col-lg-4 col-md-6" style="">
        <a
          id=""
          class="cardlink"
          (click)="onTileClick('HearingLossClicked', '/eligibilityQuizChannels')"
        >
          <div class="infocard bg-white my-2">
            <h3 class="text-dark pt-0">Hearing loss</h3>
            <div>Including long-term hearing damage and damage from a single incident</div>
          </div>
        </a>
      </div>
      <!-- Mental health condition -->
      <div class="col-lg-4 col-md-6" style="">
        <a
          id=""
          class="cardlink"
          (click)="onTileClick('MentalHealthConditionClicked', '/eligibilityQuizChannels')"
        >
          <div class="infocard bg-white my-2">
            <h3 class="text-dark pt-0">Mental health condition</h3>
            <div>Due to a traumatic event or bullying and harassment at work</div>
          </div>
        </a>
      </div>
      <!-- Sexual harassment or sexual assault -->
      <div class="col-lg-4 col-md-6" style="">
        <a
          id=""
          class="cardlink"
          (click)="onTileClick('SexualHarassmentClicked', '/eligibilityQuizChannels')"
        >
          <div class="infocard bg-white my-2">
            <h3 class="text-dark pt-0">Sexual harassment or sexual assault</h3>
            <div>
              Whether by an employer, co-worker, or a non-worker, such as a client or customer
            </div>
          </div>
        </a>
      </div>
      <!-- Not sure -->
      <div class="col-lg-4 col-md-6" style="">
        <a
          id=""
          class="cardlink"
          (click)="
            onTileClick('NotSureClicked', '/eligibilityQuizInstructions', {
              key: 'injuryType',
              value: 'NotSure'
            })
          "
        >
          <div class="infocard bg-white my-2">
            <h3 class="text-dark pt-0">Not sure</h3>
            <div>
              Because the other categories don’t apply or you’re unsure which is the best fit
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>

<!-- Injury Types Tiles Mobile -->
<section class="d-sm-none">
  <ul class="m-itembar-holder">
    <!-- Physical Injury -->
    <li>
      <a
        (click)="
          onTileClick('PhysicalInjuryClicked', '/eligibilityQuizInstructions', {
            key: 'injuryType',
            value: 'PhysicalInjury'
          })
        "
      >
        <div class="m-itembar">
          <div class="row">
            <div class="col-11">
              <div>A physical injury</div>
              <div style="font-weight: 400">
                Such as a sprain, cut, fracture, smoke inhalation, concussion, bruise, burn
                (chemical or fire), or hernia
              </div>
            </div>
            <div class="col-1 text-right d-flex align-items-center justify-content-end">
              <i class="fa fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </a>
    </li>
    <!-- An Occupational Disease -->
    <li>
      <a (click)="onTileClick('OccupationalDiseaseClicked', '/eligibilityQuizChannels')">
        <div class="m-itembar">
          <div class="row">
            <div class="col-11">
              <div>An occupational disease</div>
              <div style="font-weight: 400">
                Including exposure to a virus, such as HIV, a harmful substance, such as asbestos,
                or an irritant that causes a rash
              </div>
            </div>
            <div class="col-1 text-right d-flex align-items-center justify-content-end">
              <i class="fa fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </a>
    </li>
    <!-- Hearing Loss -->
    <li>
      <a (click)="onTileClick('HearingLossClicked', '/eligibilityQuizChannels')">
        <div class="m-itembar">
          <div class="row">
            <div class="col-11">
              <div>Hearing loss</div>
              <div style="font-weight: 400">
                Including long-term hearing damage and damage from a single incident
              </div>
            </div>
            <div class="col-1 text-right d-flex align-items-center justify-content-end">
              <i class="fa fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </a>
    </li>
    <!-- Mental health condition -->
    <li>
      <a (click)="onTileClick('MentalHealthConditionClicked', '/eligibilityQuizChannels')">
        <div class="m-itembar">
          <div class="row">
            <div class="col-11">
              <div>Mental health condition</div>
              <div style="font-weight: 400">
                Due to a traumatic event or bullying and harassment at work
              </div>
            </div>
            <div class="col-1 text-right d-flex align-items-center justify-content-end">
              <i class="fa fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </a>
    </li>
    <!-- Sexual harassment or sexual assault -->
    <li>
      <a (click)="onTileClick('SexualHarassmentClicked', '/eligibilityQuizChannels')">
        <div class="m-itembar">
          <div class="row">
            <div class="col-11">
              <div>Sexual harassment or sexual assault</div>
              <div style="font-weight: 400">
                Whether by an employer, co-worker, or a non-worker, such as a client or customer
              </div>
            </div>
            <div class="col-1 text-right d-flex align-items-center justify-content-end">
              <i class="fa fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </a>
    </li>
    <!-- Not sure -->
    <li>
      <a
        (click)="
          onTileClick('NotSureClicked', '/eligibilityQuizInstructions', {
            key: 'injuryType',
            value: 'NotSure'
          })
        "
      >
        <div class="m-itembar">
          <div class="row">
            <div class="col-11">
              <div>Not sure</div>
              <div style="font-weight: 400">
                Because the other categories don’t apply or you’re unsure which is the best fit
              </div>
            </div>
            <div class="col-1 text-right d-flex align-items-center justify-content-end">
              <i class="fa fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </a>
    </li>
  </ul>
</section>

<!--MAIN ACTION -->
<section class="mt-4">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <a class="btn-outline-blue btn-md btn mr-2 mb-2" (click)="goBack()">Back</a>
      </div>
    </div>
  </div>
</section>

<!--SUPPORT LINE -->
<section class="">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <hr />
        <div>
          <strong>
            Have a question about reporting your injury? Please call our Teleclaim team at
            <a href="tel:6042318888">604.231.8888</a> (or toll-free at
            <a href="tel:18889675377">1.888.967.5377</a>).
          </strong>
        </div>
      </div>
    </div>
  </div>
</section>
