import { HttpResponse, delay, http } from 'msw';
import { VersionDto } from 'src/app/swagger-api/swagger-api';

import { baseUrl } from './consts';

export default [
  http.get(`${baseUrl}/Version`, async () => {
    await delay(1000);

    return HttpResponse.json({ version: '1.0.0' } as VersionDto);
  }),
];
