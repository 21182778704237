import { ViewportRuler } from '@angular/cdk/scrolling';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@wsbc/ux-lib';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, delay, map, startWith, switchMap, tap } from 'rxjs/operators';

import { loadAppAuthorizationPermissions } from '../app-authorization/app-authorization.actions';
import * as AppSessionActions from './app-session.actions';

@Injectable()
export class AppSessionEffects {
  snackbarMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppSessionActions.contextMenuClicked),
      concatMap((action) => {
        this.snackBarService.showSnackbar(action.message);
        return EMPTY as Observable<{ type: string }>;
      }),
    );
  });

  applicationStarted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppSessionActions.applicationStarts),
      concatMap(({ user, appTxt, featureFlags }) =>
        of(
          loadAppAuthorizationPermissions({ user }),
          AppSessionActions.loadLookupsStart(),
          AppSessionActions.loadAppTxt({ appTxt }),
          AppSessionActions.loadFeatureFlags({ featureFlags }),
        ),
      ),
    ),
  );

  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppSessionActions.loadLookupsStart),
      // TODO: load your application-wide data here
      switchMap(() =>
        of('').pipe(
          tap(() => console.log('loading app-wide data')),
          delay(1000),
        ),
      ),
      concatMap(() =>
        of(
          AppSessionActions.LoadLookupsSuccess({} as any),
          AppSessionActions.applicationStartSuccess(),
        ),
      ),
      catchError(() =>
        of(
          AppSessionActions.applicationStartFailed({
            error: {
              errorCode: '',
              errorMessage: 'There is error occurred while loading app data.',
            },
          }),
        ),
      ),
    ),
  );

  applicationStartFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppSessionActions.applicationStartFailed),
        tap(() => this.router.navigate(['error'])),
      ),
    { dispatch: false },
  );

  applicationAuthenticationFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppSessionActions.applicationAuthenticationFailed),
        tap(() => this.router.navigate(['error', 'not-authorized'])),
      ),
    { dispatch: false },
  );

  constructor(
    viewportRuler: ViewportRuler,
    private actions$: Actions,
    private readonly snackBarService: SnackBarService,
    store: Store,
    ngZone: NgZone,
    private router: Router,
  ) {
    // action has to be dispatched in the ngZone,
    // it seems that we can't do it in the effects.
    viewportRuler
      .change(200)
      .pipe(
        startWith(() => AppSessionActions.ViewPortChanged({ ...viewportRuler.getViewportSize() })),
        map(() => AppSessionActions.ViewPortChanged({ ...viewportRuler.getViewportSize() })),
      )
      .subscribe((action) =>
        ngZone.run(() => {
          store.dispatch(action);
        }),
      );
  }
}
