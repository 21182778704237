// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.(aad|idam|anonymous).ts`.
// The list of file replacements can be found in `angular.json`.
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';

// comment it out for real ajax call
import '../mocks/browser';
// update this line to switch between different authentication method for local developing
import { environment as current } from './environment.anonymous';
import { IEnvironment } from './environment.model';

export const environment: IEnvironment = {
  production: false,
  wiirAnonUrl: 'https://wiiranon.online.dv.worksafebc.com/createreport.aspx',
  wiirUrl: 'https://wiir.online.dv.worksafebc.com/createreport.aspx',
  wrioUrl: 'https://workerinjuryreport.online.dv.worksafebc.com',
  workerDashboardUrl: 'https://op.online.dv.worksafebc.com',
  workerBcOnlineUrl: 'https://www.worksafebc.com',
  fixConfiguration: (environmentJson) =>
    current.fixConfiguration(environmentJson, { idamIndexOverwrite: 0 }),
  addGuards: current.addGuards,
  ngrxRuntimeChecks: {
    strictStateImmutability: true,
    strictActionImmutability: true,
    strictStateSerializability: true,
    strictActionSerializability: false,
    strictActionWithinNgZone: true,
    strictActionTypeUniqueness: true,
  },
  additionalModules: [StoreDevtoolsModule.instrument(), current.additionalModules],
  additionalProviders: current.additionalProviders,
  onAuthenticationSuccess: current.onAuthenticationSuccess,
  onAuthenticationFail: current.onAuthenticationFail,
};

// Included with Angular CLI.
