<section id="section-assessment">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="toggle-radio" id="selectionToggle">
          <div class="btn-group radio-group-toggle mr-3">
            <fieldset>
              <legend></legend>
              <div class="pb-0">
                <strong>
                  As of today, have you missed any time from work as a result of your injury or
                  condition?
                </strong>
                (check all that apply)
              </div>
              <div class="mb-2">
                If you have multiple employers, please consider if you’ve missed time from any of
                them.
              </div>
            </fieldset>
          </div>
          <div class="toggle-content">
            <div class="row">
              <div class="col-12">
                <div class="pb-3">
                  <form class="pt-3" id="checkboxForm" action="#">
                    <label class="container-checkbox">
                      I have not missed any time from work since I was injured
                      <input
                        type="checkbox"
                        id="payrollCheckbox"
                        name="notMissedAnyTime"
                        [(ngModel)]="notMissedAnyTime"
                        (change)="onCheckBoxChecked()"
                      />
                      <span class="checkmark-checkbox"></span>
                    </label>
                    <label class="container-checkbox">
                      I missed time during the day (or shift) I was injured
                      <input
                        type="checkbox"
                        id="payrollCheckbox"
                        name="missedTimeDuringtheDay"
                        [(ngModel)]="missedTimeDuringtheDay"
                        (change)="onCheckBoxChecked()"
                      />
                      <span class="checkmark-checkbox"></span>
                    </label>
                    <label class="container-checkbox">
                      I missed time after the day (or shift) I was injured
                      <input
                        type="checkbox"
                        id="payrollCheckbox"
                        name="payrollCheckbox"
                        [(ngModel)]="missedTimeAfterInjury"
                        (change)="onCheckBoxChecked()"
                      />
                      <span class="checkmark-checkbox"></span>
                    </label>
                  </form>
                </div>

                <div *ngIf="showAnticipateBlock()">
                  <div class="pb-0 mb-0 checkboxButtonLabel">
                    Do you anticipate missing any time from work as a result of your injury or
                    condition?
                  </div>
                  <div>
                    If you have multiple employers, please consider if you will miss time from any
                    of them.
                  </div>
                  <br />
                  <div class="d-block">
                    <div class="btn-group btn-group-toggle" data-toggle="buttons">
                      <label
                        class="btn btn-secondary"
                        [class.active]="selectedOption === 'yes-anticipate'"
                        [class.disabled]="missedTimeAfterInjury"
                      >
                        <input
                          type="radio"
                          name="missingwork"
                          id="yes-anticipate"
                          [(ngModel)]="selectedOption"
                          value="yes-anticipate"
                          (change)="onSelectionChange('yes-anticipate')"
                        />Yes
                      </label>
                      <label
                        class="btn btn-secondary"
                        [class.active]="selectedOption === 'no-anticipate'"
                        [class.disabled]="missedTimeAfterInjury"
                      >
                        <input
                          type="radio"
                          name="missingwork"
                          id="no-anticipate"
                          [(ngModel)]="selectedOption"
                          value="no-anticipate"
                          (change)="onSelectionChange('no-anticipate')"
                          [disabled]="missedTimeAfterInjury"
                        />No
                      </label>
                      <label
                        class="btn btn-secondary"
                        [class.active]="selectedOption === 'notsure-anticipate'"
                        [class.disabled]="missedTimeAfterInjury"
                      >
                        <input
                          type="radio"
                          name="missingwork"
                          id="notsure-anticipate"
                          [(ngModel)]="selectedOption"
                          value="notsure-anticipate"
                          (change)="onSelectionChange('notsure-anticipate')"
                          [disabled]="missedTimeAfterInjury"
                        />Not sure
                      </label>
                    </div>
                  </div>
                  <br />
                </div>
                <div id="callClaims" *ngIf="showPleaseNoteBlock()">
                  <blockquote class="blockquote-border-yellow">
                    <h3 class="pt-0">Please note</h3>
                    <div>
                      If you do miss time from in the future, please let us know by calling our
                      Claims Call Centre at
                      <a href="#">604.231.8888</a> (toll-free at <a href="#">1.888.967.5377</a>).
                      <br /><br />
                      You may continue with your report.
                    </div>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="buttons-singleincident">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="py-3">
          <a
            class="btn-outline-blue btn-md btn mr-2 mb-2"
            [routerLink]="['/eligibilityQuizInstructions']"
            >Back</a
          >
          <div (click)="onContinueWrapperClicked()" style="cursor: pointer">
            <button
              id=""
              type="button"
              class="mb-2 btn-blue btn-md btn"
              data-toggle="modal"
              data-target="#accountModal"
              (click)="openDialogBox()"
              [disabled]="continueButtonEnabled"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<wsbc-modal-dialog
  [visible]="visible"
  [title]="title"
  [size]="size"
  [titleId]="'dialog-title-id'"
  [closeBtnId]="'dialog-close-button-id'"
  [secondaryBtnId]="'dialog-secondary-btn-id'"
  [primaryBtnId]="'dialog-primary-btn-id'"
  primaryBtnLabel="Continue"
  (closeBtnClicked)="onCloseBtnClicked()"
  (primaryBtnClicked)="onContinueBtnClicked()"
  (secondaryBtnClicked)="onCloseBtnClicked()"
>
  <article class="">
    <p>
      You need an online services account to continue your report online. This will allow you to
      save your report as you go along.
    </p>

    <h3>Don't have an account?</h3>
    <p>Click "Sign up" on the next page, follow the prompts, and log in.</p>

    <h3>Already have an account?</h3>
    <p>Log in on the next page.</p>
  </article>
</wsbc-modal-dialog>
