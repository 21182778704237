import { setupWorker } from 'msw/browser';

import { handlers } from './handlers';

export const worker = setupWorker(...handlers);
worker.start({
  onUnhandledRequest(req, print) {
    const urlParts = new URL(req.url);

    // specify routes to exclude
    const excludedRoutes = ['/favicon.ico', '/manifest.json', '/logo192.png'];
    const isExcluded = excludedRoutes.some((route) => urlParts.pathname.includes(route));
    if (isExcluded) {
      return;
    }

    // specify host to exclude
    const excludedHosts = [
      'www.googletagmanager.com',
      'dc.services.visualstudio.com',
      'www.google-analytics.com',
    ];
    const isHostExcluded = excludedHosts.some((host) => urlParts.host.includes(host));
    if (isHostExcluded) {
      return;
    }

    // specify exclude extensions
    const excludeExtensions = ['.js', '.woff', '.woff2'];
    const isExtensionExcluded = excludeExtensions.some((ext) => urlParts.pathname.endsWith(ext));
    if (isExtensionExcluded) {
      return;
    }

    print.warning();
  },
});
