import { createReducer, on } from '@ngrx/store';

import * as AppAuthorizationActions from './app-authorization.actions';
import { Permission } from './permissions';
import { roleToPermissionMap } from './roleToPermissionMap';
import { Role } from './roles';

export const appAuthorizationFeatureKey = '[awesomeForm]appAuthorization';

export interface IUser {
  userName: string;
  email?: string;
  isAnonymous: boolean;
  roles: string[];
}

export interface State {
  user: IUser | null;
  permissions: Permission[];
}

export const initialState: State = {
  user: null,
  permissions: [],
};

export const reducer = createReducer(
  initialState,

  on(AppAuthorizationActions.loadAppAuthorizationPermissions, (_, { user }) => ({
    ...initialState,
    user,
    permissions: user.roles
      .map((strRole) => (Role as any)[strRole] as Role)
      .reduce((acc, role) => [...acc, ...roleToPermissionMap[role]], [] as Permission[]),
  })),
);
