import { InjectionToken, ModuleWithProviders, Provider, Type } from '@angular/core';
import { Routes } from '@angular/router';
import { RuntimeChecks, Store } from '@ngrx/store';

export const EnvironmentJson = new InjectionToken<any>('EnvironmentJson');
export const FeatureFlagsJson = new InjectionToken<any>('featureFlagsJson');

export interface IEnvironment {
  production: boolean;
  // hook for environment specific transformation of the `environment.json` file
  wiirAnonUrl: string;
  wiirUrl: string;
  wrioUrl: string;
  workerDashboardUrl: string;
  workerBcOnlineUrl: string;
  fixConfiguration: (
    envJson: IEnvironmentJson,
    option?: IFixConfigurationOption,
  ) => IEnvironmentJson;
  //list of providers for app modules
  additionalProviders: Provider[];
  //list of module for app modules
  additionalModules: Type<any> | ModuleWithProviders<{}> | any[];
  //add route guards to list of routes.
  addGuards: (routes: Routes) => Routes;
  ngrxRuntimeChecks?: Partial<RuntimeChecks>;
  // hooks for handling user login event
  onAuthenticationSuccess: (
    user: any,
    appTxt: any,
    featureFlags: IFeatureFlags,
    store: Store,
  ) => void;
  onAuthenticationFail: (err: any, appTxt: any, store: Store) => void;
}

export interface IEnvironmentJsonForAppInsights {
  applicationInsights: any;
}

export interface IEnvironmentJsonForApi {
  apiUrl: string;
}

export interface IEnvironmentForFeatureFlags {
  featureFlags: IFeatureFlags;
}

export interface IFeatureFlags {
  disableRecaptcha?: boolean;
}

export interface IEnvironmentJson
  extends IEnvironmentJsonForAppInsights,
    IEnvironmentForFeatureFlags,
    IEnvironmentJsonForApi {}

export interface IFixConfigurationOption {}

declare global {
  interface Window {
    __wsbc_username: string;
  }
}
