import { inject } from '@angular/core';
import { CanMatchFn, Route, UrlSegment } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { isAppInitialized } from 'src/app/store/app-session/app-session.selectors';

export const appStartGuard: CanMatchFn = (_: Route, segments: UrlSegment[]) => {
  if (segments[0]?.path === 'error') {
    return true;
  }
  return inject(Store)
    .select(isAppInitialized)
    .pipe(filter((isAppInitialized) => isAppInitialized));
};
