import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AppAuthorizationEffects } from './app-authorization.effects';
import * as fromAppAuthorization from './app-authorization.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromAppAuthorization.appAuthorizationFeatureKey,
      fromAppAuthorization.reducer,
    ),
    EffectsModule.forFeature([AppAuthorizationEffects]),
  ],
})
export class AppAuthorizationModule {}
